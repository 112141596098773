@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
:root {
  --primary-white: #fff;
  --primary-light-gray: #f4f4f4;
  --primary-gray: #b5bbc5;
  --primary-dark-gary: #666666;
  --primary-dark: rgb(52, 58, 64);
  --primary-orange: #DB772F;
  --primary-red: #f26334;
  --primary-hover-red: #d55c11;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0,0,0,0.7);
  --primary-green: green;
}

body {
  font-family: "Roboto Condensed", sans-serif !important;
}

/***************** NAVBAR ***************************/

.logo {
  width: 6rem;
}

.nav-bg-color {
  background-color: #2b404e !important;
}

/* ----------- MENU LINKS --------------*/
nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #fff !important;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid #666666;
  border-top: 0.1875rem solid var(--primary-dark-gary);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus, .navbar-nav > li > a:hover {
  border-top: 0.1875rem solid #DB772F;
  border-top: 0.1875rem solid var(--primary-orange);
}

/***************** HEADER ***************************/

.header-wrapper {
  position: relative;
  background: url(/static/media/wall-and-laptop-background.f9e274f4.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: #DB772F;
  color: var(--primary-orange);
  text-transform: uppercase;
}

/*----------- TYPED TEXT -----------------*/
.typed-text {
  font-size: 2rem;
  color: #fff;
  color: var(--primary-white);
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/*----------- HEADER MAIN OFFER BUTTON -----------------*/
.btn-main-offer {
  border: 1px solid #f26334;
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #fff;
  color: var(--primary-white);
  margin-top: 2rem;
  text-decoration: none;
}

.btn-main-offer:hover {
  color: #fff;
  color: var(--primary-white);
  text-decoration: none;
  background-color: #d55c11;
  background-color: var(--primary-hover-red);
}

/*----------- PARTICLES -----------------*/
.tsparticles {
  position: absolute;
  height: 100vh;
  z-index: 1;
}

/***************** ABOUT ME ***************************/
.photo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 20rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid #DB772F;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: #DB772F;
  color: var(--primary-orange);
  text-transform: uppercase;
}

.initials{
  font-size: 20px;
  font-weight: bolder;
}

/* styling paper */
#paper {
  width: 550px;
  height: 400px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  box-shadow: 0px 0px 5px 0px #888;
}

/* styling red vertical line */
#paper::before {
  content: '';
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 40px;
  background-color: rgba(255,0,0,0.6);
}

/* styling blue horizontal lines */
#pattern {
  height: 100%;
  background-image: repeating-linear-gradient(white 0px, white 24px, teal 25px);
}

/* styling text content */
#content {
  padding-top: 5px;
  padding-left: 56px;
  padding-right: 16px;
  line-height: 25px;
  font-family: 'Kristen Itc', cursive;
  font-size: 0.85rem;
  letter-spacing: 1px;
  word-spacing: 5px;
}

@media(max-width: 468px) {
  .profile-img {
    width: 10rem;
  }

  #paper {
    width: 350px;
    height: 475px;
  }

  #content {
    font-size: 0.71rem;
  }

  .initials{
    font-size: 15px;
  }
}

@media(min-width: 768px) {
  .photo-wrapper {
    margin-top: 5rem;
  }
}

/***************** Projects ***************************/
.projects-wrapper {
  background-color: #f4f4f4;
  background-color: var(--primary-light-gray);
  padding: 3rem 0;
}

.projects-wrapper h1 {
  color: rgb(52, 58, 64);
  color: var(--primary-dark);
}

.projects-image {
  width: 15rem;
  border: 1px solid #DB772F;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5rem;
}

.projects-image-box {
  position: relative;
  margin: 1rem;
}

/*---------------- OVERFLOW BOX ------------------*/
.overflow {
  position: absolute;
  opacity: 0;
  background-color: #000000;
  background-color: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid #f26334;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.projects-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.projects-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: #DB772F;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.projects-image-box:hover .projects-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/*---------------- OVERFLOW BOX ------------------*/
.popupbox-wrapper {
  margin-top: 5rem;
}

.projects-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
  border: 1px solid black;
}

.popupbox-content p {
  padding-top: 1rem;
}

.hyper-link {
  cursor: pointer;
  color: #DB772F;
  color: var(--primary-orange);

}

.hyper-link:hover {
  color: #f26334;
  color: var(--primary-red);
  
}

/*---------------- MEDIA ------------------*/
@media(max-width: 768px) {
  .projects-image-popupbox {
    width: 100%;
  }
}

@media(max-height: 500px) {
  .popupbox-wrapper {
    height: 100%;
  }

  .projects-image-popupbox {
    width: 50%;
  }
}

@media(max-height: 28px) {
  .popupbox-wrapper {
    height: 90%;
  }

  .projects-image-popupbox {
    width: 30%;
  }
}

@media(max-width: 912px) {

  .projects-image-popupbox {
    width: 100%;
  }
}

/***************** EXPERIENCE ***************************/
.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: #DB772F;
  color: var(--primary-orange);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background-color: #b5bbc5;
  background-color: var(--primary-gray);
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both; 
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid rgb(52, 58, 64);
  border: 0.3125rem solid var(--primary-dark);
  background-color: #DB772F;
  background-color: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 1;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: #666666;
  color: var(--primary-dark-gary);
  direction: ltr; 
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: #f26334;
  color: var(--primary-red);
  font-weight: 900;
}

/*---------------- MEDIA ------------------*/
@media(max-width: 768px) {
  .experience {
    padding: 0.7rem;
  }

  .experience .container {
    padding-left: 0;
  }

  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }

  .timeline-block-right {
    float: none;
    width: 100%;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }

}

/***************** SERVICES ***************************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background-color: #f4f4f4;
  background-color: var(--primary-light-gray);
}

.services h1 {
  color: rgb(52, 58, 64);
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background-color: #DB772F;
  background-color: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: #fff;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid #DB772F;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background-color: #fff;
  background-color: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background-color: #DB772F;
  background-color: var(--primary-orange);
  border-bottom: 0.3125rem solid rgb(52, 58, 64);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon {
  color: rgb(52, 58, 64);
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background-color: #fff;
  background-color: var(--primary-white);
}

.services .box:hover p, .services .box:hover h3 {
  color: #fff;
  color: var(--primary-white);
}

/***************** Blog ***************************/
.blog-wrapper {
  background-color: #fff;
  background-color: var(--primary-white);
  padding: 3rem 0;
}

.blog-wrapper h1 {
  color: #DB772F;
  color: var(--primary-orange);
}

.blog-image {
  width: 15rem;
  border: 1px solid #DB772F;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5rem;
}

.blog-image-box {
  position: relative;
  margin: 1rem;
}

/*---------------- OVERFLOW BOX ------------------*/
.overflow {
  position: absolute;
  opacity: 0;
  background-color: #000000;
  background-color: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid #f26334;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.blog-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.blog-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: #DB772F;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.blog-image-box:hover .blog-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/*---------------- OVERFLOW BOX ------------------*/
.popupbox-wrapper {
  margin-top: 5rem;
}

.blog-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
  border: 1px solid black;
}

.popupbox-content p {
  padding-top: 1rem;
}

.hyper-link {
  cursor: pointer;
  color: #DB772F;
  color: var(--primary-orange);

}

.hyper-link:hover {
  color: #f26334;
  color: var(--primary-red);
  
}

/*---------------- MEDIA ------------------*/
@media(max-width: 768px) {
  .blog-image-popupbox {
    width: 100%;
  }
}

@media(max-height: 500px) {
  .popupbox-wrapper {
    height: 100%;
  }

  .blog-image-popupbox {
    width: 50%;
  }
}

@media(max-height: 28px) {
  .popupbox-wrapper {
    height: 90%;
  }

  .blog-image-popupbox {
    width: 30%;
  }
}

@media(max-width: 912px) {

  .blog-image-popupbox {
    width: 100%;
  }
}

/***************** TESTIMONIALS ***************************/
.testimonials {
  background-image: url(/static/media/testimonials-bg.75612be7.jpg);
  background-size: cover;
  background-color: #000000;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
}

.testimonials h1 {
  color: #fff;
  color: var(--primary-white);
  padding: 3rem 0;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background-color: rgba(0,0,0,0.7);
  background-color: var(--primary-transparent-black);
}

.myCarousel {
  color: #fff;
  color: var(--primary-white);
  background: transparent;
  margin-top: -6%;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  /*height: 17.875rem;*/
}

.myCarousel h3 {
  color: #DB772F;
  color: var(--primary-orange);
  font-weight: 600;
  text-transform: uppercase;
}

.myCarousel p {
  color: #b5bbc5;
  color: var(--primary-gray);
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important; 
}

/*---------------- MEDIA ------------------*/
@media(max-width: 768px){
  .myCarousel h3 {
    font-size: 1rem;
  }

  .carousel .slide img {
    width: 5rem !important;
    border-radius: 50%;
  }

  .myCarousel p {
    color: #b5bbc5;
    color: var(--primary-gray);
    font-size: 0.8rem;
  }
}

/***************** CONTACTS ***************************/
.contacts {
  background-color: #fff;
  background-color: var(--primary-white);
  padding: 3rem 0;
}

.contacts h1 {
  color: #DB772F;
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: #666666;
  color: var(--primary-dark-gary);
  font-size: 1.2rem;
}

/*---------------- INPUTS ------------------*/
.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-gary);
  border-radius: 0;
  color: #666666;
  color: var(--primary-dark-gary);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts input::-webkit-input-placeholder, .contacts textarea::-webkit-input-placeholder {
  color: #b5bbc5;
  color: var(--primary-gray);
  opacity: 1; /* Firefox */
}

.contacts input::placeholder, .contacts textarea::placeholder {
  color: #b5bbc5;
  color: var(--primary-gray);
  opacity: 1; /* Firefox */
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background: transparent;
  color: #DB772F;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-gary);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
  color: #666666;
  color: var(--primary-dark-gary);
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.1rem;
  background-color: #DB772F;
  background-color: var(--primary-orange);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: #fff;
  color: var(--primary-white);
  background-color: #f26334;
  background-color: var(--primary-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: green;
  color: var(--primary-green);
}

/*---------------- MEDIA ------------------*/
@media(max-width: 768px){
  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}

/***************** FOOTER ***************************/
.footer {
  background-color: #000000;
  background-color: var(--primary-black);
  color: #666666;
  color: var(--primary-dark-gary);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: #666666;
  color: var(--primary-dark-gary);
}

.footer a:hover {
  text-decoration: none;
  color: #DB772F;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}
